import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {ENDPOINTS} from "../../config/ENDPOINTS";

export const updateStatus = createAsyncThunk(
  '[PUT] admin/updateStatus',
  async () => {
    const response = await axios.put(`${ENDPOINTS.changeOrderStatus}`)


  }
)

export const getOrderDetails = createAsyncThunk(
  '[GET] admin/getOrderDetails',
  async (id, _) => {
    const response = await axios.get(`${ENDPOINTS.orderDetails}/${id}`)

    return response.data
  }
)

export const getOrders = createAsyncThunk(
  '[get] products/all',
  async () => {
    const response = await axios.get(`${ENDPOINTS.getAllOrders}`);

    return response.data
  }
)

export const getMenu = createAsyncThunk(
  '[GET] admin/menu',
  async () => {
    const response = await axios.get(`${ENDPOINTS.getMenu}`)

    return response.data
  }
)

interface product {
  name: string,
  status: number,

  orders: any,
  ordersStatus: 'idle' | 'pending' | 'fulfilled' | 'rejected',

  menu: any,
  menuStatus: 'idle' | 'pending' | 'fulfilled' | 'rejected',

  orderDetails: any,
  orderDetailsStatus: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

const initialState: product = {
  name: '',
  status: 1,

  orders: [],
  ordersStatus: 'idle',

  menu: [],
  menuStatus: 'idle',

  orderDetails: [],
  orderDetailsStatus: 'idle'

}

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    changeStatus: (state, {payload}) => {
      state.status = payload
    },
    unsetOrderDetails: (state) => {
      state.orderDetailsStatus = 'idle'
      state.orderDetails = []
    }
  },
  extraReducers: {
    // Menu
    // @ts-ignore
    [getMenu.pending]: (state) => {
      state.menuStatus = 'pending'
    },
    // @ts-ignore
    [getMenu.fulfilled]: (state, {payload}) => {
      state.menuStatus = 'fulfilled'
      state.menu = payload
    },
    // @ts-ignore
    [getMenu.rejected]: (state) => {
      state.menuStatus = 'rejected'
    },


    // Orders
    // @ts-ignore
    [getOrders.pending]: (state) => {
      state.ordersStatus = 'pending'
    },
    // @ts-ignore
    [getOrders.fulfilled]: (state, {payload}) => {
      state.ordersStatus = 'fulfilled'
      state.orders = payload
    },
    // @ts-ignore
    [getOrders.rejected]: (state) => {
      state.ordersStatus = 'rejected'
    },


    // Order-Details
    // @ts-ignore
    [getOrderDetails.pending]: (state) => {
      state.orderDetailsStatus = 'pending'
    },
    // @ts-ignore
    [getOrderDetails.fulfilled]: (state, {payload}) => {
      state.orderDetailsStatus = 'fulfilled'
      state.orderDetails = payload
    },
    // @ts-ignore
    [getOrderDetails.rejected]: (state) => {
      state.orderDetailsStatus = 'rejected'
    }
  }
})

export const { changeStatus, unsetOrderDetails } = adminSlice.actions

export default adminSlice.reducer
